<template>
  <v-bottom-navigation
    grow
    fixed
    dark
    class="bottom-nav-bar primary--text grey lighten-2"
  >
    <!-- home -->
    <v-btn :to="{ name: 'Home' }" class="px-0">
      <span>{{ $t("bottom.home") }}</span>
      <v-icon>$home</v-icon>
    </v-btn>
    <!-- dashboard -->
    <v-btn @click="handleLogin('/profile')" class="px-0">
      <span>{{ $t("bottom.account") }}</span>
      <v-icon>$profile</v-icon>
    </v-btn>
    <!-- additional button -->
    <v-btn
      v-if="additionalBtnSrc && additionalBtnLink"
      :to="additionalBtnLink"
      class="px-0"
    >
      <v-img max-width="65px" contain :src="additionalBtnSrc"></v-img>
    </v-btn>
    <!-- preferiti -->
    <v-btn @click="handleLogin('/preferred')" class="px-0">
      <span
        v-html="
          hasFavorites ? $t('bottom.favourite') : $t('bottom.bestselling')
        "
      ></span>
      <v-icon>$heartfull</v-icon>
    </v-btn>
    <!-- sito istituzionale app -->
    <v-btn @click.prevent.stop="siteBtnClicked" class="px-0">
      <img height="50px" src="/logo/logo-small.png" />
    </v-btn>
    <!-- carrello -->
    <!-- <v-btn
      @click="!isAuthenticated ? handleLogin() : (drawerRight = !drawerRight)"
    >
      <v-badge
        :content="totalItems"
        :value="totalItems > 0"
        :key="totalItems"
        transition="bounce"
        color="secondary darken-1 default--text font-weight-bold"
        overlap
        offset-x="20"
        class="d-flex flex-column"
      >
        <v-icon>$cart</v-icon>
        <span>{{ $t("bottom.cart") }}</span>
      </v-badge>
    </v-btn> -->
  </v-bottom-navigation>
</template>

<script>
import login from "~/mixins/login";

import { forEachCartItem } from "~/service/ebsn";

import get from "lodash/get";
import { mapActions, mapGetters, mapState } from "vuex";

export default {
  mixins: [login],
  props: {
    applicationConfig: { type: Object, required: false }
  },
  data() {
    return {};
  },
  methods: {
    ...mapActions({
      updatedDrawerLeft: "app/updatedDrawerLeft",
      updatedDrawerRight: "app/updatedDrawerRight",
      updatedDrawerLinks: "app/updatedDrawerLinks"
    }),
    siteBtnClicked() {
      this.drawerLeft = !this.drawerLeft;
    },
    async handleLogin(path) {
      if (this.isAuthenticated) {
        this.$router.push(path);
      } else {
        let isLoggedIn = await this.doLogin();
        if (isLoggedIn) {
          this.$router.push(path);
        }
      }
    }
  },
  computed: {
    ...mapGetters({
      isAuthenticated: "cart/isAuthenticated",
      hasFavorites: "cart/hasFavorites"
    }),
    ...mapState({
      storeDrawerLeft: state => state.app.drawerLeft,
      storeDrawerRight: state => state.app.storeDrawerRight,
      storeDrawerLinks: state => state.app.drawerLinks,
      cart: ({ cart }) => cart.cart
    }),
    totalItems() {
      let total = 0;
      forEachCartItem(this.cart, function(item) {
        total += item.quantity > 0 ? item.quantity : 1;
      });
      return total;
    },
    drawerLeft: {
      get() {
        return this.storeDrawerLeft;
      },
      set(value) {
        this.updatedDrawerLeft(value);
      }
    },
    drawerRight: {
      get() {
        return this.storeDrawerRight;
      },
      set(value) {
        this.updatedDrawerRight(value);
      }
    },
    drawerLinks: {
      get() {
        return this.storeDrawerLinks;
      },
      set(value) {
        this.updatedDrawerLinks(value);
      }
    },
    additionalBtnLink() {
      return get(
        this.applicationConfig,
        "metaData.template_model.ADDITIONAL_BTN_LINK"
      );
    },
    additionalBtnSrc() {
      return get(
        this.applicationConfig,
        "metaData.template_model.ADDITIONAL_BTN_IMG"
      );
    }
  }
};
</script>
<style lang="scss">
.v-main {
  padding-bottom: calc(160px + env(safe-area-inset-top)) !important;
}
.keyboard-open {
  .v-main {
    padding-bottom: calc(80px + env(safe-area-inset-top)) !important;
  }
}
.platform-ios {
  .v-main {
    padding-bottom: calc(210px + env(safe-area-inset-top)) !important;
  }
}
.platform-ios.keyboard-open {
  .v-main {
    padding-bottom: calc(110px + env(safe-area-inset-top)) !important;
  }
}

.bottom-nav-bar {
  height: $bottom-navigation-bar-height;
  color: $primary !important;
  position: fixed;
  bottom: 0;
  height: calc(
    #{$bottom-navigation-bar-height} + constant(safe-area-inset-top) + 20px
  ) !important;
  padding-top: constant(safe-area-inset-top);
  padding-left: constant(safe-area-inset-left);
  padding-right: constant(safe-area-inset-right);
  height: calc(
    #{$bottom-navigation-bar-height} + env(safe-area-inset-top) + 20px
  ) !important;
  padding-top: env(safe-area-inset-top);
  padding-left: env(safe-area-inset-left);
  padding-right: env(safe-area-inset-right);
  z-index: 5 !important;
  .v-btn {
    min-width: auto !important;
    padding: 0 5px;
    font-size: 0.9rem !important;
    .v-btn__content {
      span {
        color: $primary !important;
      }
      .v-icon {
        color: $primary !important;
        font-size: 28px;
      }
      .v-image {
        padding: 5px;
        height: 100%;
        width: auto;
      }
    }
  }
}
.platform-ios {
  .bottom-nav-bar {
    //height: $bottom-navigation-bar-height-ios !important;
    position: fixed;
    bottom: 0;
    padding-top: constant(safe-area-inset-top);
    padding-left: constant(safe-area-inset-left);
    padding-right: constant(safe-area-inset-right);
    max-height: calc(
      #{$bottom-navigation-bar-height-ios} + env(safe-area-inset-top) + 20px
    ) !important;
    padding-top: calc(env(safe-area-inset-top));
    padding-left: env(safe-area-inset-left);
    padding-right: env(safe-area-inset-right);
    padding-bottom: calc(110px + env(safe-area-inset-bottom));
    .v-icon {
      font-size: 32px !important;
    }
  }
}

.keyboard-open {
  .bottom-nav-bar {
    display: none !important;
  }
}
</style>
